import React from 'react';
import { Input as BaseInput, SIZE } from 'baseui/input';

const getInputFontStyle = ({ $theme }) => {
  return {
    color: $theme.colors.textDark,
    '::-ms-clear': {
      display: 'none',
    }, // fix bug X button in IE11
    ...$theme.typography.fontBold14,
  };
};

const Input = ({ ...props }) => {
  return (
    <BaseInput
      overrides={{
        Input: {
          style: ({ $theme }) => {
            return {
              ...getInputFontStyle({ $theme }),
              '@media only screen and (max-width: 1200px)': {
                width: '120px !important',
              },
            };
          },
        },
      }}
      {...props}
    />
  );
};

export { SIZE };
export default Input;
