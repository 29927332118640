import { styled } from "baseui";
import { NavLink as NavLinks } from "react-router-dom";

export const SidebarWrapper = styled("div", ({ $theme }) => ({
  width: "200px",
  display: "flex",
  flexShrink: "0",
  flexDirection: "column",
}));

export const MenuWrapper = styled("div", ({ $theme }) => ({
  width: "100%",
  // height: "100%",
  display: "flex",
  maxHeight: "90vh",
  flexDirection: "column",
  overflow: "auto",
}));

export const NavLink = styled(NavLinks, ({ $theme }) => ({
  ...$theme.typography.fontBold16,
  outline: "0",
  color: $theme.colors.textDark,
  display: "flex",
  alignItems: "center",
  padding: "15px 35px 15px 15px",
  textDecoration: "none",
  maxHeight: "3rem",
  ":hover": {
    color: "#00C58D",
  },
}));

export const Svg = styled("span", ({ $theme }) => ({
  width: "16px",
  marginRight: "15px",
  display: "flex",
  alignItems: "center",
}));
export const LogoutBtn = styled("button", ({ $theme }) => ({
  ...$theme.typography.fontBold16,
  outline: "0",
  color: "#FA5858",
  backgroundColor: "transparent",
  border: "0",
  display: "flex",
  position: "absolute",
  bottom: 0,
  alignItems: "center",
  padding: "10px 25px 20px 40px",
  textDecoration: "none",
  cursor: "pointer",

  "@media only screen and (max-width: 767px)": {
    width: "100%",
    padding: "20px 35px",
  },
}));

export const TitleLayout = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const Title = styled("b", {
  display: "flex",
  flex: 1,
  justifyContent: "center",
  fontWeight: "700",
  color: "rgb(22, 31, 106)",
  fontSize: 20,
  margin: "16px",
});

export const ButtonMenu = styled("button", {
  display: "flex",
  marginRight: "5px",
  padding: "4px",
});
