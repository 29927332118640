import React, { useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBack';
import {
  SidebarWrapper,
  NavLink,
  MenuWrapper,
  Svg,
  LogoutBtn,
  TitleLayout,
  Title,
  ButtonMenu,
} from './Sidebar.style';
import {
  PRODUCTS,
  CATEGORY,
  ORDERS,
  CUSTOMERS,
  MAKER,
  PRICE_SETTINGS,
  // POINT,
  POINT_ACTION,
  DASHBOARD,
  CAMPAIGN,
  TAG_SETTINGS,
  SALE,
  POINT_EXCHANGE,
  HANBAITEN_MAIL,
  GIFTS,
  INTRO_PRODUCT,
  VR_LINK,
  MAKER_LINK,
} from '../../../settings/constants';
import { AuthContext, Role } from '../../../context/auth';

import {
  ProductIcon,
  SidebarCategoryIcon,
  OrderIcon,
  CustomerIcon,
  CouponIcon,
  LogoutIcon,
  RefundIcon,
  GiftBox,
  SmartPhone,
  CheckMark,
  DashboardIcon,
  DeliveryIcon,
  CoinIcon,
  PencilIcon,
  DressIcon,
  MedicineIcon,
  GroceryIcon,
  CartIcon,
  InboxIcon,
  AlertDotIcon,
} from '../../../components/AllSvgIcon';
import { MenuIconContainer, TopbarRightSide } from '../Topbar/Topbar.style';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useQuery } from '@apollo/react-hooks';
import { GET_PROFILE } from '../../../graphql/query/user.query';
import { IconButton, Button, Divider } from '@material-ui/core';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import numeral from 'numeral';
import { version } from '../../Login/Login';
import { theme } from '../../../theme';
// import { version } from "mobile-detect";
import EmailIcon from '@material-ui/icons/Email';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import { PROJECT } from '../../../constant';

export const sidebarMenus = [
  {
    name: '売上集計',
    path: DASHBOARD,
    exact: true,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <AssignmentIcon style={{ width: '16px' }} />,
  },
  {
    name: '注文履歴',
    path: ORDERS,
    exact: true,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <OrderIcon />,
  },
  {
    name: 'ユーザアクティビティ',
    path: POINT_ACTION,
    exact: true,
    roleAccess: [Role.admin],
    icon: <AccessibilityNewIcon style={{paddingRight:"5px"}}/>,
  },
  // {
  //   name: "ポイント交換",
  //   path: POINT_EXCHANGE,
  //   exact: true,
  //   roleAccess: [Role.admin],
  //   icon: <GiftBox />,
  // },
  {
    name: 'ユーザ管理',
    path: CUSTOMERS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <CustomerIcon />,
  },
  {
    name: '商品管理',
    path: PRODUCTS,
    exact: false,
    roleAccess: [Role.admin],
    icon: <ProductIcon />,
  },
  process.env.REACT_APP_PROJECT === PROJECT.chugoku
    ? {
        name: '景品管理',
        path: GIFTS,
        exact: false,
        roleAccess: [Role.admin],
        icon: <GiftBox />,
      }
    : null,
  // {
  //   name: "価格設定",
  //   path: PRICE_SETTINGS,
  //   exact: false,
  //   roleAccess: [Role.admin, Role.hanbaiten],
  //   icon: <CouponIcon />,
  // },

  {
    name: 'カテゴリー',
    path: CATEGORY,
    exact: false,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <SidebarCategoryIcon />,
  },
  {
    name: 'メーカー',
    path: MAKER,
    exact: false,
    roleAccess: [Role.admin, Role.hanbaiten],
    icon: <RefundIcon />,
  },
  {
    name: 'メール受信',
    path: HANBAITEN_MAIL,
    exact: false,
    roleAccess: [Role.hanbaiten],
    icon: <EmailIcon style={{ paddingRight: "5px" }}/>,
  },
  {
    name: 'VRリンク集',
    path: VR_LINK,
    exact: false,
    roleAccess: [Role.admin],
    icon: <AlertDotIcon />,
  },
  process.env.REACT_APP_PROJECT === PROJECT.tokyo
    ? {
        name: 'ﾒｰｶｰﾘﾝｸ集​',
        path: MAKER_LINK,
        exact: false,
        roleAccess: [Role.admin],
        icon: <InboxIcon />,
      }
    : null,
  // {
  //   name: "キャンペーン",
  //   path: CAMPAIGN,
  //   exact: false,
  //   roleAccess: [Role.admin, Role.hanbaiten],
  //   icon: <PencilIcon />,
  // },
  // {
  //   name: "タグ設定",
  //   path: TAG_SETTINGS,
  //   exact: false,
  //   roleAccess: [Role.admin, Role.hanbaiten],
  //   icon: <DashboardIcon />,
  // },

  process.env.REACT_APP_PROJECT !== PROJECT.chugoku
    ? {
        name: 'どんどんセール',
        path: SALE,
        exact: false,
        roleAccess: [Role.admin],
        icon: <CouponIcon />,
      }
    : null,
  {
    name: 'ﾋﾞﾃﾞｵﾘﾝｸ集',
    path: INTRO_PRODUCT,
    exact: false,
    roleAccess: [Role.admin],
    icon: <SubscriptionsIcon style={{ paddingRight: "5px" }}/>,
  },
];
export default withRouter(function Sidebar({
  refs,
  style,
  onMenuItemClick,
  changeShowSidebar,
}: any) {
  const { signout, role } = useContext(AuthContext);
  const location = useLocation();
  const { data, loading } = useQuery(GET_PROFILE);

  return (
    <SidebarWrapper ref={refs} style={style}>
      <MenuWrapper>
        <>
          <TitleLayout>
            <Title>
              管理者画面
              <span
                style={{
                  fontSize: 10,
                  marginTop: 'auto',
                }}
              >
                {version.v}
              </span>
            </Title>
            <ButtonMenu onClick={changeShowSidebar}>
              <ArrowBackIosIcon style={{ fontSize: 20 }} />
            </ButtonMenu>
          </TitleLayout>
        </>
        {data && role === 'hanbaiten' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              alignItems: 'center',
              marginBottom: 8,
            }}
          >
            <div style={{ color: theme.colors.primary }}>
              <b>{data.profile.username}</b>
            </div>
            <div>
              保有ポイント:
              <span style={{ color: '#c00' }}>
                {numeral(data.profile.point).format('0,0.[00]')}
              </span>{' '}
              pts
            </div>
          </div>
        )}
        <Divider />
        {sidebarMenus
          .filter((item) => {
            return item !== null && item.roleAccess.includes(role);
          })
          .map((menu: any, index: number) => (
            <NavLink
              to={menu.path}
              key={index}
              exact={menu.exact}
              activeStyle={{
                color: theme.colors.primary,
                backgroundColor: '#f1f1f1',
              }}
              onClick={onMenuItemClick}
            >
              {menu.icon ? <Svg>{menu.icon}</Svg> : ''}
              {menu.name}
            </NavLink>
          ))}
      </MenuWrapper>
      <LogoutBtn
        onClick={() => {
          signout();
        }}
      >
        <Svg>
          <LogoutIcon />
        </Svg>
        ログアウト
      </LogoutBtn>
    </SidebarWrapper>
  );
});
